import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import auth from './auth';
import chat from './chat';
import educationGroup from './education-group';
import feedback from './feedback';
import lesson from './lesson';
import metric from './metric';
import teacher from './teacher';
import users from './users';
import lms from './lms';
import { AUTH } from '@/services/auth';
import { notify } from '../notification';

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;

axios.interceptors.request.use(async (config) => {
  const updatedConfig = { ...config };
  const azureToken = await AUTH.getInternalToken();
  const authToken = azureToken || '';

  if (authToken && updatedConfig.headers) {
    updatedConfig.headers.Authorization = `Bearer ${authToken}`;
  }
  return updatedConfig;
}, (error) => Promise.reject(error));

axios.interceptors.response.use(undefined, (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      AUTH.logout();
    }
    if (String(error.response.status).startsWith('5')) {
      router.push('/').catch(() => ({}));
      notify({ type: 'info', content: 'Ошибка сервера' });
    }
  }
  return Promise.reject(error);
});

export const API = {
  auth: auth(axios),
  chat: chat(axios),
  educationGroup: educationGroup(axios),
  feedback: feedback(axios),
  lesson: lesson(axios),
  metric: metric(axios),
  teacher: teacher(axios),
  users: users(axios),
  lms: lms(axios),
};

export default (): void => {
  Vue.prototype.$api = API;
};
