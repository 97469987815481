















import { Component, Vue, Watch } from 'vue-property-decorator';
import { store } from '@/store';

@Component
export default class Notify extends Vue {
  snackbar = false;

  get paramsNotify() {
    return store.notify.state.paramsNotify;
  }

  get paramsSnackbar() {
    return store.notify.state.paramsSnackbar;
  }

  @Watch('paramsNotify')
  showNotify() {
    this.snackbar = true;
  }
}
