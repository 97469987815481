/* eslint-disable no-shadow */
export enum Roles {
  ADMIN = 'admin',
  USER = 'user',
  TEAMLEADER = 'teamleader',
}
export interface IRole {
  value: Roles;
  description: string;
}

export interface IRoleDto {
  value: string;
  description: string;
}
