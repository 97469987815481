import { Mutations } from 'vuex-smart-module';
import { IUser } from '@/types/user';
import { AuthState } from './state';

export class AuthMutations extends Mutations<AuthState> {
  savePathBeforeLogin(path: string) {
    this.state.pathBeforeRoute = path;
  }

  setUser(user: IUser | null) {
    this.state.user = user;
  }
}
