












import { Component, Prop, Vue } from 'vue-property-decorator';
import { store } from '@/store';

@Component
export default class extends Vue {
  @Prop({ type: Number, default: 120 }) readonly size!: number;

  get loading(): boolean {
    return store.root.state.isLoading;
  }
}
