import { AxiosStatic } from 'axios';
import { AUTH } from '@/services/auth';
import { AuthApi } from './types';
import { readStream } from '@/services/api/helpers';

export default (axios: AxiosStatic): AuthApi => ({
  /**
   *  Сохранение текущего azure аккаунта в локальной базе
   */
  async saveUser() {
    const user = await axios.get('/account/save');
    return user;
  },

  async getUserPhoto() {
    const token = await AUTH.getAccessToken();
    const response = await fetch('https://graph.microsoft.com/v1.0/me/photos/48x48/$value', {
      headers: { Authorization: `Bearer ${token}` },
    });
    const blob = await readStream(response);
    return blob;
  },
});
