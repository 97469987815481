import { Actions } from 'vuex-smart-module';
import { API } from '@/services/api';
import { AuthMutations } from './mutations';
import { AuthState } from './state';
import { AUTH } from '@/services/auth';

export class AuthActions extends Actions<
  AuthState,
  never,
  AuthMutations,
  AuthActions
> {
  /**
   * Сохранение/обновление аккаунта в базе EDS по токену. Обновление microsoft аватарки.
   * Обновление state.user в store vuex
   */
  public async saveUser() {
    if (!AUTH.azure.getActiveAccount()) return;
    const blob = await API.auth.getUserPhoto();
    let user = (await API.auth.saveUser()).data;

    if (blob) { // обновление аватарки
      const reader = new FileReader();
      let src: string | ArrayBuffer | null = null;
      reader.readAsDataURL(blob);
      reader.onload = async () => {
        src = reader.result;
        if (typeof src === 'string') {
          user = (await API.users.updateCurrent({ id: user.id, avatar: src as string })).data;
        }
      };
    }
    this.mutations.setUser(user);
  }
}
