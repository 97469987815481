import { VueConstructor } from 'vue';
import { ITeacher } from '@/types/teacher';
import { IUser } from '@/types/user';
import router from '@/router';

const getUserFullName = (user: ITeacher | IUser, reverse = false) => {
  if (reverse) return `${user.lastName} ${user.firstName}`;
  return `${user.firstName} ${user.lastName}`;
};

const getStudentInitials = (username: string) => {
  const letters = username.split(' ').map((name) => (name ? name[0].toUpperCase() : ''));
  return letters.join('');
};

const getUserInitials = (user: ITeacher | IUser) => {
  if (user) return `${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}`;
  return '??';
};

const openPage = (path: string) => {
  const route = router.resolve({ path });
  window.open(route.href, '_blank');
};

const GlobalPlugin = {
  install(Vue: VueConstructor) {
    Vue.prototype.$getUserInitials = getUserInitials;
    Vue.prototype.$getStudentInitials = getStudentInitials;
    Vue.prototype.$getUserFullName = getUserFullName;
    Vue.prototype.$openPage = openPage;
  },
};

export const G = {
  getUserInitials,
  getStudentInitials,
  getUserFullName,
  openPage,
};
declare module 'vue/types/vue' {
  interface Vue {
    $getUserInitials: typeof getUserInitials,
    $getUserFullName: typeof getUserFullName,
    $getStudentInitials: typeof getStudentInitials,
    $openPage: typeof openPage
  }
}

export default GlobalPlugin;
