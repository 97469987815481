import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Roles } from '@/types/role';
import { savePathBeforeLogin } from '@/router/helpers';
import { AUTH } from '@/services/auth';
import { AccountInfoExtended } from '@/services/auth/types';
import { T } from '@/plugins/localization';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'empty' },
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/',
    name: 'lessons',
    meta: { roles: [Roles.ADMIN, Roles.USER, Roles.TEAMLEADER] },
    component: () => import('@/views/lessons/lessons.vue'),
  },
  {
    path: '/lesson/:id',
    name: 'lesson',
    meta: { roles: [Roles.ADMIN, Roles.USER, Roles.TEAMLEADER] },
    component: () => import('@/views/lessons/lesson.vue'),
    children: [
      {
        path: ':section',
        meta: { roles: [Roles.ADMIN, Roles.USER, Roles.TEAMLEADER] },
        component: () => import('@/views/lessons/lesson.vue'),
        children: [
          {
            path: ':subsection',
            meta: { roles: [Roles.ADMIN, Roles.USER, Roles.TEAMLEADER] },
            component: () => import('@/views/lessons/lesson.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/my-feedback',
    name: 'myFeedback',
    meta: { roles: [Roles.USER, Roles.TEAMLEADER] },
    component: () => import('@/views/myFeedback.vue'),
  },
  {
    path: '/order-lesson',
    name: 'orderLesson',
    meta: { roles: [Roles.ADMIN, Roles.USER, Roles.TEAMLEADER] },
    component: () => import('@/views/orderLesson.vue'),
  },
  {
    path: '/admin/users',
    name: 'users',
    meta: { roles: [Roles.ADMIN] },
    component: () => import('@/views/admin/users.vue'),
  },
  {
    path: '/admin/feedback',
    name: 'feedback',
    meta: { roles: [Roles.ADMIN] },
    component: () => import('@/views/admin/feedback.vue'),
  },
  {
    path: '/admin/other',
    name: 'other',
    meta: { roles: [Roles.ADMIN] },
    component: () => import('@/views/admin/other.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to?.meta?.title ?? T.page.main.title;
  const roles: Array<Roles> = to?.meta?.roles;
  const user: AccountInfoExtended | null = AUTH.azure.getActiveAccount() as AccountInfoExtended || null;
  if (roles && !user) {
    savePathBeforeLogin(to.path);
    next('/login');
  } else if (roles && user) {
    try {
      const intersection = roles.filter((role) => user.idTokenClaims.roles.includes(role));
      if (intersection.length) next();
      else next('/');
    } catch (e) {
      savePathBeforeLogin(to.path);
      next('/login');
    }
  } else next();
});

export default router;
