import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import App from './App.vue';
import router from './router';
import { storeInstance } from './store';
import vuetify from './plugins/vuetify';
import api, { API } from '@/services/api';
import auth, { AUTH } from '@/services/auth';
import notification, { notify } from '@/services/notification';
import colorsPlugin from '@/plugins/colors';
import globalPlugin from '@/plugins/global';
import formattingPlugin from '@/plugins/formatting';
import textPlugin from '@/plugins/localization';

import '@/assets/styles/vars.scss';
import '@/assets/styles/tooltips.scss';
import '@/assets/styles/scrollbars.scss';

Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.use(api);
Vue.use(auth);
Vue.use(notification);
Vue.use(colorsPlugin);
Vue.use(globalPlugin);
Vue.use(formattingPlugin);
Vue.use(textPlugin);

declare module 'vue/types/vue' {
  // eslint-disable-next-line no-shadow
  interface Vue {
    $api: typeof API
    $auth: typeof AUTH
    $notify: typeof notify
  }
}

new Vue({
  router,
  store: storeInstance,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
