import { store } from '@/store';

export const savePathBeforeLogin = (path: string): void => {
  store.auth.mutations.savePathBeforeLogin(path);
};

export const getPathAfterLogin = (): string => {
  const path = store.auth.state.pathBeforeRoute;
  store.auth.mutations.savePathBeforeLogin('/');
  return path;
};
