














import { Component, Vue } from 'vue-property-decorator';
import Notify from '@/components/Notify.vue';
import Loader from '@/components/Loader.vue';
import Drawer from '@/components/navigation/LeftDrawer.vue';

@Component({ components: { Notify, Drawer, Loader } })
export default class App extends Vue {
  get layout() {
    const layout = this.currentLayout ?? 'default';
    return () => import(`@/views/layouts/${layout}`);
  }

  get currentLayout(): string {
    return this.$route?.meta?.layout;
  }

  get drawer() {
    if (this.currentLayout === 'empty') return false;
    return true;
  }
}
