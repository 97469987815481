










































































import { Component, Vue } from 'vue-property-decorator';
import UserAvatar from '@/components/UserAvatar.vue';
import { IUser } from '@/types/user';
import Account from '@/components/Account.vue';
import { AUTH } from '@/services/auth';
import { store } from '@/store';
import { Roles } from '@/types/role';

@Component({
  components: {
    UserAvatar,
    Account,
  },
})
export default class Drawer extends Vue {
  showAccount = false
  user: IUser | null = null
  list = [
    { title: this.$t.title.lessons, icon: 'mdi-video-account', link: '/' },
    {
      title: this.$t.title.users,
      icon: 'mdi-account-star',
      link: '/admin/users',
      roles: [Roles.ADMIN],
    },
    {
      title: this.$t.title.feedback,
      icon: 'mdi-comment',
      link: '/admin/feedback',
      roles: [Roles.ADMIN],
    },
    {
      title: this.$t.title.feedback,
      icon: 'mdi-comment',
      link: '/my-feedback',
      roles: [Roles.USER, Roles.TEAMLEADER],
    },
    {
      title: this.$t.title.orderLesson,
      icon: 'mdi-archive-star',
      link: '/order-lesson',
      roles: [Roles.ADMIN, Roles.USER, Roles.TEAMLEADER],
    },
  ];

  async mounted() {
    await store.auth.actions.saveUser();
    this.user = AUTH.getUser();
  }

  get filtredList() {
    return this.list.filter((item) => {
      if (item.roles !== undefined) {
        return this.user ? item.roles.includes(this.user.role.value) : false;
      }
      return true;
    });
  }

  logout() {
    AUTH.logout();
  }
}
