


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ITeacher } from '@/types/teacher';
import { IUser } from '@/types/user';

@Component
export default class UserAvatar extends Vue {
  @Prop({ required: false, default: 48 })
  size?: number | string;

  @Prop({ required: false, default: 'subtitle-1' })
  fontSize?: string;

  @Prop({ required: false })
  user?: IUser | ITeacher;

  @Prop({ required: false })
  student?: string;

  @Prop({ required: false })
  classList?: string;

  get color() {
    if (this.user) return this.$getUserColor(this.user.id, 'HEX');
    if (this.student) return this.$getStudentColor(this.student, 'HEX', 'darken');
    return '#fff';
  }

  get letters() {
    if (this.user) return this.$getUserInitials(this.user);
    if (this.student) return this.$getStudentInitials(this.student);
    return '??';
  }

  get avatar(): any {
    if (this.user && ('avatar' in this.user)) return this.user.avatar;
    return undefined;
  }
}
