/**
 * https://medium.com/adidoescode/azure-ad-for-user-authentication-with-vue-and-nestjs-4dab3e96d240
 */
import Vue from 'vue';
import { store } from '@/store';
import { INotify } from '@/types/inotify';

export const notify = (params: INotify) => store.notify.mutations.showNotify(params);

export default (): void => {
  Vue.prototype.$notify = notify;
};
