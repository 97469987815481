import { AxiosStatic } from 'axios';
import { TeacherApi } from './types';
import { ITeacher } from '@/types/teacher';

export default (axios: AxiosStatic): TeacherApi => ({
  fetchTeachersWithLessons() {
    return axios.get<ITeacher[]>('/teachers');
  },

  fetchTeachersAll() {
    return axios.get<ITeacher[]>('/teachers/all');
  },

  fetchTeamLeadersWithLessons() {
    return axios.get<ITeacher[]>('/teachers/team-leaders');
  },

  fetchTeachersByTeamLeader(teamLeaderId: number) {
    return axios.get<ITeacher[]>(`/teachers/team-leader/${teamLeaderId}`);
  },

  fetchMyTeachers() {
    return axios.get<ITeacher[]>('/teachers/my');
  },
});
