/**
 * https://medium.com/adidoescode/azure-ad-for-user-authentication-with-vue-and-nestjs-4dab3e96d240
 */
import Vue from 'vue';
import { PublicClientApplication, Configuration, AuthenticationResult, AccountInfo } from '@azure/msal-browser';
import { store } from '@/store';
import router from '@/router';

const clientId = process.env.VUE_APP_AAD_CLIENT_ID;
const tenantId = process.env.VUE_APP_AAD_TENANT_ID;

const redirectUri = `${window.location.origin}/login`;

const config: Configuration = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

const tokenConfig = {
  scopes: ['User.Read'],
};

const popupWindowAttributes = {
  popupSize: {
    height: 400,
    width: 500,
  },
  popupPosition: {
    top: 150,
    left: 500,
  },
};

const azure: PublicClientApplication = new PublicClientApplication(config);

azure.handleRedirectPromise().then((tokenResponse) => {
  // Check if the tokenResponse is null
  // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
  // If the tokenResponse === null, you are not coming back from an auth redirect.
}).catch((error) => {
  // handle error, either in the library or coming back from the server
});

async function login() {
  try {
    const response: AuthenticationResult = await azure.loginPopup({ ...tokenConfig, popupWindowAttributes });
    if (response) azure.setActiveAccount(response.account);
  } catch (error) {
    azure.setActiveAccount(null);
    throw error;
  }
}

async function logout() {
  try {
    router.push('/login').catch(() => ({}));
    await azure.logoutPopup({
      account: azure.getActiveAccount(),
      postLogoutRedirectUri: `${redirectUri}/login`,
      mainWindowRedirectUri: `${redirectUri}/login`,
      popupWindowAttributes,
    });
    store.auth.mutations.setUser(null);
  } catch (error) {
    console.log(error);
  }
}

async function acquireToken() {
  try {
    const token = await azure.acquireTokenSilent({
      ...tokenConfig,
      account: azure.getActiveAccount() as AccountInfo | undefined,
    });
    return token;
  } catch (error) {
    console.log(error);
    // eslint-disable-next-line no-return-await
    router.push('/login').catch(() => ({}));
  }
}

async function getInternalToken() {
  return (await acquireToken())?.idToken;
}

async function getAccessToken() {
  return (await acquireToken())?.accessToken;
}

const getUser = () => store.auth.state.user;

export const AUTH = {
  azure,
  login,
  logout,
  getAccessToken,
  getInternalToken,
  getUser,
};

export default (): void => {
  Vue.prototype.$auth = AUTH;
};
