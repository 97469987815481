import { format, mode, RGB } from './types';

/* eslint-disable no-shadow */
export class Color {
    private rgb!: RGB
    private color!: RGB | string
    private middleArea = 170;

    constructor() {
      this.rgb = { r: this.random(), g: this.random(), b: this.random() };
      this.color = this.rgb;
    }

    private get shiftArea() {
      return Math.round((255 - this.middleArea) / 2);
    }

    get hex(): Color { // конвертирует RGB цвет в hex 16-ти битный для css
      const { r, g, b } = this.color as RGB;
      this.color = `#${this.numberToHex(r)}${this.numberToHex(g)}${this.numberToHex(b)}`;
      return this;
    }

    get darken(): Color {
      const { r, g, b } = this.color as RGB;
      this.color = { r: r - this.shiftArea, g: g - this.shiftArea, b: b - this.shiftArea };
      return this;
    }

    get lighten(): Color {
      const { r, g, b } = this.color as RGB;
      this.color = { r: r + this.shiftArea, g: g + this.shiftArea, b: b + this.shiftArea };
      return this;
    }

    public get(): RGB | string {
      const result = this.color;
      this.color = this.rgb;
      return result;
    }

    public mode(mode: mode): Color {
      if (mode === 'lighten') return this.lighten;
      if (mode === 'darken') return this.darken;
      return this;
    }

    public format(format: format): Color {
      if (format === 'HEX') return this.hex;
      return this;
    }

    private random = () => Math.floor(this.middleArea * (0.25 + Math.random()))

    private numberToHex = (c: number): string => { // конвертирует число в 16-ти битную строку
      const hex = c.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    };
}
