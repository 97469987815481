import { Getters } from 'vuex-smart-module';
import { ILesson } from '@/types/lesson';
import { FeedbackState } from './state';
import { IFeedback } from '@/types/feedback';

export class FeedbackGetters extends Getters<FeedbackState> {
  get lessons(): Array<ILesson> {
    if (this.state.lessonsWithMyCommentsBySearch === null) return [];
    if (!this.state.lessonsWithMyCommentsBySearch?.length) return this.state.lessonsWithMyComments;
    return this.state.lessonsWithMyCommentsBySearch;
  }

  get commentsByLesson(): Array<IFeedback> {
    if (this.state.selectedLesson === 0) return this.state.allMyComments;
    const indx = this.state.selectedLesson - 1;
    if (this.getters.lessons[indx] !== undefined) return this.getters.lessons[indx].comments;
    return [];
  }

  get commentsBySearch(): Array<IFeedback> {
    let result: Array<IFeedback> = [];
    if (!this.state.searchComments) result = this.getters.commentsByLesson;
    else {
      const search = this.state.searchComments.toLowerCase();
      result = this.getters.commentsByLesson.filter((comment) => {
        if (comment.comment.toLowerCase().includes(search)) return true;
        if (comment.metric.subsection.title.toLowerCase().includes(search)) return true;
        if (comment.updatedAt.includes(search)) return true;
        if (comment.createdAt.includes(search)) return true;
        if (comment.user.firstName.toLowerCase().includes(search)) return true;
        if (comment.user.lastName.toLowerCase().includes(search)) return true;
        if (String(comment.lessonId).includes(search)) return true;
        return false;
      });
    }

    if (!result) return [];
    return result.sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });
  }
}
