import { IFeedback } from '@/types/feedback';
import { ILesson } from '@/types/lesson';

export class FeedbackState {
  commentsByLesson: IFeedback[] = [];
  allMyComments: Array<IFeedback> = [];
  lessonsWithMyComments: Array<ILesson> = [];
  lessonsWithMyCommentsBySearch: Array<ILesson> | null = [];
  selectedLesson = 0;
  searchComments = '';
}
