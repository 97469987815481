import { VueConstructor } from 'vue';
import text from './locales/ru';

const TextPlugin = {
  install(Vue: VueConstructor) {
    Vue.prototype.$t = text;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $t: typeof text
  }
}

export const T = {
  ...text,
};

export default TextPlugin;
