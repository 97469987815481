import { format } from 'date-fns';

// Установка 2020 года в связи с временными данными за 20-й год.
export const formatDate = (date: Date) => format(date.setFullYear(2020), 'yyyy-MM-dd HH:mm:ss');

export const readStream = async (response: Response): Promise<Blob | undefined> => {
  if (response.body && response.statusText === 'OK') {
    const buffer = await response.arrayBuffer();
    const bytes = new Uint8Array(buffer);
    const blob = new Blob([bytes.buffer]);
    return blob;
  }
  return undefined;
};
