import { Actions } from 'vuex-smart-module';
import { FeedbackState } from './state';
import { FeedbackMutations } from './mutations';
import { IFeedback } from '@/types/feedback';
import { API } from '@/services/api';
import { store } from '@/store';
import { ILesson } from '@/types/lesson';

export class FeedbackActions extends Actions<
FeedbackState,
never,
FeedbackMutations,
FeedbackActions
> {
  public async fetchCommentsByLesson(lessonId: number) {
    const comments: IFeedback[] = (await API.feedback.getCommenstByLesson(lessonId)).data;
    this.mutations.setCommentsByLesson(comments);
  }

  public async getMyFeedback() {
    const { user } = store.auth.state;
    const lessons: Array<ILesson> = [];
    const commentsAll: Array<IFeedback> = [];

    if (user) {
      lessons.push(...(await API.lesson.getLessonsWithCommentsByUser(user.id)).data);
      lessons.forEach(async (lesson) => {
        lesson.comments = (await API.feedback.getCommenstByLesson(lesson.id)).data;
        commentsAll.push(...lesson.comments);
      });

      this.mutations.setLessonsWithMyComments(lessons);
      this.mutations.setAllMyComments(commentsAll);
    }
  }
}
