import { Module } from 'vuex-smart-module';
import { PlayerState } from './state';
import { PlayerMutations } from './mutations';
import { PlayerActions } from './actions';

export default new Module({
  state: PlayerState,
  mutations: PlayerMutations,
  actions: PlayerActions,
});
