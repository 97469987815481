import { Actions } from 'vuex-smart-module';
import { PlayerMutations } from './mutations';
import { PlayerState } from './state';

export class PlayerActions extends Actions<
PlayerState,
never,
PlayerMutations,
PlayerActions
> {
}
