/* eslint-disable no-shadow */
import { VueConstructor } from 'vue';
import { Color } from '@/plugins/colors/color.entity';
import { format, mode, RGB } from './types';

const accent = 'green';
const userColorsStore: { [userId: number]: Color } = {};
const studentColorsStore: { [studentName: string]: Color } = {};

const colorRandomRGB = (mode: mode = 'lighten'): RGB | undefined => new Color().mode(mode).get() as RGB;
const colorRandomHEX = (mode: mode = 'lighten'): string => new Color().mode(mode).hex.get() as string;

const getUserColor = (userId: number, format: format = 'RGB', mode: mode = 'darken'): RGB | string => {
  if (!userColorsStore[userId]) userColorsStore[userId] = new Color();
  return userColorsStore[userId].mode(mode).format(format).get();
};

const getStudentColor = (studentName: string, format: format = 'RGB', mode: mode = 'lighten'): RGB | string => {
  if (!studentColorsStore[studentName]) studentColorsStore[studentName] = new Color();
  return studentColorsStore[studentName].mode(mode).format(format).get();
};

const gradientColor = (percent: number, opacity = 1): string => {
  const whiteMix = 120;
  const maxBright = 255 - whiteMix;
  const gradient = maxBright * 2;
  const brightByValue = (percent * gradient) / 100;
  const rgb = { r: whiteMix, g: whiteMix, b: whiteMix };
  if (brightByValue <= maxBright) {
    rgb.r += maxBright;
    rgb.g += brightByValue;
  } else {
    rgb.r += gradient - brightByValue;
    rgb.g += maxBright;
  }
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
};

const ColorsPlugin = {
  install(Vue: VueConstructor) {
    Vue.prototype.$accent = accent;
    Vue.prototype.$colorRandomRGB = colorRandomRGB;
    Vue.prototype.$colorRandomHEX = colorRandomHEX;
    Vue.prototype.$getUserColor = getUserColor;
    Vue.prototype.$getStudentColor = getStudentColor;
    Vue.prototype.$gradientColor = gradientColor;
  },
};

export const C = {
  accent,
  colorRandomRGB,
  colorRandomHEX,
  getUserColor,
  getStudentColor,
  gradientColor,
};

declare module 'vue/types/vue' {
  interface Vue {
    $accent: typeof accent
    $colorRandomRGB: typeof colorRandomRGB
    $colorRandomHEX: typeof colorRandomHEX
    $gradientColor: typeof gradientColor
    $getUserColor: typeof getUserColor
    $getStudentColor: typeof getStudentColor
  }
}

export default ColorsPlugin;
