










































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { API } from '@/services/api';
import { IUser, IUserDtoUpdate } from '@/types/user';
import { emailRules, passwordEdit, passwordRules, name } from '@/utils/validation';
import { AUTH } from '@/services/auth';

@Component({})
export default class UserAccount extends Vue {
  @Prop() readonly showAccount!: boolean
  dialog = false;
  editedItem: IUserDtoUpdate | null = this.defaultItem;

  get currentUser(): IUser | null {
    return AUTH.getUser();
  }

  get defaultItem(): IUserDtoUpdate | null {
    return this.currentUser ? {
      id: this.currentUser?.id,
      email: this.currentUser?.email,
      lastName: this.currentUser?.lastName,
      firstName: this.currentUser?.firstName,
      password: '',
      currentPassword: '',
    } : null;
  }

  rules = {
    isValid: true,
    showPassword: false,
    showCurrentPassword: false,
    email: emailRules,
    passwordEdit,
    passwordRules,
    name,
  };

  get formTitle() {
    return this.$t.action.account.info;
  }

  get buttonSave() {
    return { title: this.$t.button.save, color: this.$accent };
  }

  @Watch('showAccount')
  showAccountState(val: boolean) {
    if (val) this.dialog = true;
  }

  @Watch('dialog')
  dialogWatch(val: any) {
    if (!val) {
      this.$emit('close', val);
      this.editedItem = this.defaultItem;
    }
  }

  async updateUser() {
    if (this.editedItem) {
      try {
        await API.users.updateCurrent(this.editedItem);
        this.dialog = false;
        this.$notify({
          type: 'success',
          content: this.$t.notify.account.updated,
        });
      } catch (error: any) {
        this.dialog = false;
        this.$notify({
          type: 'error',
          content: error.response.data.error,
        });
      }
    }
  }
}
