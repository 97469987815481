import { Mutations } from 'vuex-smart-module';
import { IFeedback } from '@/types/feedback';
import { FeedbackState } from './state';
import { ILesson } from '@/types/lesson';

export class FeedbackMutations extends Mutations<FeedbackState> {
  setCommentsByLesson(comments: IFeedback[]) {
    this.state.commentsByLesson = comments;
  }

  setLessonsWithMyComments(lessons: Array<ILesson>) {
    this.state.lessonsWithMyComments = lessons;
  }

  setLessonsWithMyCommentsBySearch(lessons: Array<ILesson> | null) {
    this.state.lessonsWithMyCommentsBySearch = lessons;
  }

  setSelectedLesson(indxOfArrayLessons: number) {
    this.state.selectedLesson = indxOfArrayLessons;
  }

  setSearchComments(search: string) {
    this.state.searchComments = search;
  }

  setAllMyComments(comments: Array<IFeedback>) {
    this.state.allMyComments = comments;
  }
}
