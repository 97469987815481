export default {
  page: {
    main: {
      title: 'EDS Анализ видео',
    },
  },
  title: {
    all: 'Все',
    auth: 'Авторизация',
    total: 'Всего',
    firsName: 'Имя',
    lastName: 'Фамилия',
    password: 'Пароль',
    email: 'Email',
    role: 'Роль',
    activated: 'Действующий',
    updatedAt: 'Дата обновления',
    createdAt: 'Дата создания',
    actions: 'Действия',
    teachers: 'Преподаватели',
    comments: 'Комментарии',
    users: 'Пользователи',
    feedback: 'Обратная связь',
    other: 'Другое',
    lessons: 'Список уроков',
    lesson: 'Урок',
    orderLesson: 'Заказ урока',
    theme: 'Тема',
    group: 'Группа',
  },
  my: {
    feedback: 'Мои обращения',
    teachers: 'Мои преподаватели',
    lessons: 'Мои уроки',
  },
  subtitle: {
    chat: 'Сообщения в чате',
    feedback: 'Обратная связь к метрике',
    chart: {
      feedback: 'Количество сообщений в чате',
      calls: 'Обращения к ученикам',
    },
    lastComment: 'Последний комментарий',
  },
  button: {
    add: 'Добавить',
    cancel: 'Отмена',
    create: 'Создать',
    save: 'Сохранить',
    login: 'Войти',
    logout: 'Выход',
    sort: {
      arrange: 'Упорядочить',
      new: 'Сначала новые',
      old: 'Сначала старые',
    },
  },
  notify: {
    login: {
      success: 'Добро пожаловать',
      error: 'Необходима авторазиция через Microsoft Azure',
    },
    user: {
      created: 'Пользователь успешно создан',
      updated: 'Пользователь успешно изменен',
    },
    account: {
      updated: 'Аккаунт успешно изменен',
    },
    lesson: {
      empty: 'Уроки не найдены',
    },
    order: {
      create: 'Обработка урока заказана',
      cancel: 'Обработка урока отменена',
      onlyTeamleader: 'Урок может заказывать только тимлидер',
    },
  },
  confirm: {
    comment: {
      delete: 'Удалить этот комментарий?',
    },
  },
  action: {
    rewind: 'Перемотать',
    rewindTo: 'Перемотать на',
    intervals: 'Показать отметки на видео',
    user: {
      create: 'Создать пользователя',
      update: 'Редактировать пользователя',
    },
    comment: {
      create: 'Добавить комментарий',
      visible: 'Все пользователи видят этот коментрий',
      unvisible: 'Пользователи не видят этот коментрий',
    },
    account: {
      info: 'Информация об аккаунте',
      update: 'Редактировать аккаунт',
    },
    lesson: {
      to: 'Перейти к уроку',
      order: 'Заказать обработку',
      canсel: 'Отменить обработку вне очереди',
    },
  },
  select: {
    teamLeader: 'Выберите тимлидера',
    teacher: 'Выберите преподавателя',
    group: 'Выберите группу',
  },
  search: {
    lesson: 'Поиск по урокам',
    user: 'Поиск по пользователям',
    comment: 'Поиск по сообщениям',
  },
  empty: {
    chat: 'В данном уроке не было сообщений в чате',
    feedback: 'Нет сообщений',
    myFeedback: 'Здесь отображаются все Ваши сообщения.',
    myLessons: 'Здесь отображаются уроки, в которых вы оставляли обратную связь',
    calls: 'В данном уроке не удалось определить обращения',
    teacher: 'У вас нет преподавателей',
  },
  validate: {
    comment: 'Превышено число символов 255',
  },
};
