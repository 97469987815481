/* eslint-disable max-len */
/* eslint-disable no-shadow */
import { IChatMessage } from './chat';
import { ISubsection } from './section';

export type visualType = 'chart_line' | 'chart_line_extended' | 'chart_line_intervals' | 'chart_bar' | 'button_time' | 'chart_radar' | 'chart_donut' |'chart_donut_extended' |'text' | 'button_intervals' | 'chart_line_speech'

export type visualizationComponent = 'LineChart' | 'LineChartExtanded' | 'TimeLineChart' | 'BarChart' | 'DonutChart' | 'PolarChart' | 'TimestampButtons' | 'Text' | 'Intervals' | 'LessonInfo' | 'LineChartSpeech';
export enum VisualizationComponents {
  chart_line_extended = 'LineChartExtanded',
  chart_line = 'LineChart',
  chart_line_intervals = 'TimeLineChart',
  chart_bar = 'BarChart',
  button_intervals = 'Intervals',
  button_time = 'TimestampButtons',
  chart_donut_extended = 'DonutChart',
  chart_donut = 'DonutChart',
  chart_radar = 'PolarChart',
  text = 'LessonInfo',
  chart_line_speech = 'LineChartSpeech'
}

// New types
export type buttonTimeValue = number
export type buttonIntervalsValue = { from: number, to: number }
export type chartBarValue = { proba: number, time: number }
export type chartLineValue = { value: string, time: Array<number> }
export type chartTimeLineValue = { value: string, time: Array<buttonIntervalsValue> }
export type chartRadarValue = { key: string, value: number }
export type chartDonutValue = { value: string, count: number }
export type chartDonutExtendedValue = { value: string, count: number }
export type chartHistExtendedValue = { value: string, time: Array<number> }
export type chartLineSpeechValue = { message: string, from: number, to: number }

export type buttonTimeValues = Array<buttonTimeValue>
export type buttonIntervalsValues = Array<buttonIntervalsValue>
export type chartBarValues = Array<chartBarValue>
export type chartLineValues = Array<chartLineValue>
export type chartTimeLineValues = Array<chartTimeLineValue>
export type chartRadarValues = Array<chartRadarValue>
export type chartDonutValues = Array<chartDonutValue>
export type chartDonutExtendedValues = Array<chartDonutExtendedValue>
export type chartHistExtendedValues = Array<chartHistExtendedValues>
export type chartLineExtandedValues = { [username: string]: Array<IChatMessage> }
export type chartLineSpeechValues = { [username: string]: Array<chartLineSpeechValue> }

export type metricValues = buttonTimeValues | buttonIntervalsValues | chartBarValues | chartLineValues | chartRadarValues | chartDonutValues | chartDonutExtendedValues | chartHistExtendedValues | chartLineExtandedValues | chartTimeLineValues | chartLineSpeechValues

export interface ITextMetric { unit: string, title: string, value: string | number, alias: string, subtitle?: string }
export interface IVisualMetric {
  id: number;
  lessonId: number;
  value: metricValues | IChatMessage[] | undefined;
  visualization?: visualizationComponent
  text?: { [alias: string]: {unit: string, title: string, value: string | number} };
  title: string,
  visualType: visualType,
  priority?: number
}

export interface IMetricData {
  id: number;
  lessonId: number;
  metricType: string;
  visualType: visualType;
  verified?: boolean
  value: {
    text?: { [alias: string]: {unit: string, title: string, value: string | number} },
    value?: metricValues | IChatMessage[],
  };
  subsection: ISubsection
}
