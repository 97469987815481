import { VueConstructor } from 'vue';

const formattingTimeDate = (date: string, separator = ' - ', reverse?: boolean) => {
  const stringToDate = new Date(date);
  const dateToStringTime = stringToDate.toLocaleTimeString();
  const dateToStringDate = stringToDate.toLocaleDateString();
  return reverse ? `${dateToStringDate}${separator}${dateToStringTime}` : `${dateToStringTime}${separator}${dateToStringDate}`;
};

const formattingDate = (date: string) => {
  const stringToDate = new Date(date);
  return stringToDate.toLocaleDateString();
};

const formattingTime = (date: string) => {
  const stringToDate = new Date(date);
  return stringToDate.toLocaleTimeString();
};

const secondsToTime = (timeInSeconds: number): any => new Date(timeInSeconds * 1000).toISOString().substring(11, 19);

const timeToSeconds = (timeString: string): number => {
  const timeArray = timeString.split(':');
  const [hours, minutes, seconds] = timeArray;
  return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
};

const getFirstAndLastDaysOfMonth = (date: Date): { from: Date, to: Date } => {
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  lastDate.setHours(23);
  lastDate.setMinutes(59);
  lastDate.setSeconds(59);
  return {
    from: new Date(date.getFullYear(), date.getMonth()),
    to: lastDate,
  };
};

const FormattingPlugin = {
  install(Vue: VueConstructor) {
    Vue.prototype.$formattingTimeDate = formattingTimeDate;
    Vue.prototype.$formattingDate = formattingDate;
    Vue.prototype.$formattingTime = formattingTime;
    Vue.prototype.$secondsToTime = secondsToTime;
    Vue.prototype.$timeToSeconds = timeToSeconds;
    Vue.prototype.$getFirstAndLastDaysOfMonth = getFirstAndLastDaysOfMonth;
  },
};

export const F = {
  formattingTimeDate,
  formattingDate,
  formattingTime,
  secondsToTime,
  timeToSeconds,
  getFirstAndLastDaysOfMonth,
};
declare module 'vue/types/vue' {
  interface Vue {
    $formattingTimeDate: typeof formattingTimeDate
    $formattingDate: typeof formattingDate
    $formattingTime: typeof formattingTime
    $secondsToTime: typeof secondsToTime
    $timeToSeconds: typeof timeToSeconds
    $getFirstAndLastDaysOfMonth: typeof getFirstAndLastDaysOfMonth
  }
}

export default FormattingPlugin;
