import { IEducationGroup } from '@/types/education-group';
import { ILesson } from '@/types/lesson';
import { ITeacher } from '@/types/teacher';

export class MainState {
  teamLeadersWithLessons: ITeacher[] = [];
  teachersWithLessons: ITeacher[] = [];
  teachersByTeamLeader: ITeacher[] = [];
  myTeachers: ITeacher[] = [];
  educationGroupsWithLessons: IEducationGroup[] = [];
  educationGroupsByTeacher: IEducationGroup[] = [];
  educationGroupsByTeamLeader: IEducationGroup[] = [];
  lessons: ILesson[] = [];
  filtersApi: { [key: string]: any } = {};
  filterTeamLeader: ITeacher | null | undefined = null;
  filterTeacher: ITeacher | null | undefined = null;
  filterGroup: IEducationGroup | null = null;
  filterOrder = 'DESC';
  offset = 0;
}
